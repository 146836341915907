<template>
  <div class="menu-button">
    <div
      v-for="(item, index) in menu"
      :key="index"
      class="menu-item"
      :class="{
        active: checkActive(item, item.activeUrl)
      }"
      @click="clickMenuItem(item)"
    >
      <div class="action-wrap__icon">
        <CommonBaseImg class="icon" :src="item.icon" :alt="item.name" />
      </div>
      <div class="text">{{ item.name }}</div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import CommonBaseImg from '~/components/common/base-img'
import { LOBBY_GAME_URLS, PAGE_URLS } from '~/config/page-url'
import { BOTTOM_MENU } from '~/constants/menu'
import { useAppStore } from '~/store/app'
import { useToggleMenu } from '~/composables/useToggleMenu'
import { IMenuBottom } from '~/types/menu.type'
import { useGameStore } from '~~/store/game'

const { $pinia } = useNuxtApp()
const route = useRoute()
const store = useAppStore()
const { toggleMenuAccount } = useToggleMenu()
const storeGame = useGameStore($pinia)
const { providerGames } = storeToRefs(storeGame)

const menu = computed(() => {
  return BOTTOM_MENU.filter((item) => item.name !== (route.path === PAGE_URLS.HOME ? 'Trang chủ' : 'Khuyến mãi'))
})

const urlGameLobby = `${PAGE_URLS.GAME}/${providerGames?.value[0]?.alias || 'table-game'}`

const clickMenuItem = (item: IMenuBottom) => {
  if (!item?.url) {
    toggleMenuAccount()
    return
  }

  if (item.key === 'games') {
    navigateTo(urlGameLobby)
  } else {
    navigateTo(item.url)
  }
}
const checkActive = (item: IMenuBottom, activeUrl: string) => {
  if (item.url === BOTTOM_MENU[1].url) {
    return false
  } else if (route.query.type === 'lottery' && route.name !== 'casino') {
    return route.fullPath === item.url || route.fullPath.includes(item.url)
  } else if (route.path === LOBBY_GAME_URLS.QUAY_SO && activeUrl === '/game') {
    return false
  } else {
    return route.fullPath.includes(item.url) || route.fullPath.includes(activeUrl)
  }
}
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/common/menu-bottom/index.scss" />
