<template>
  <div class="deposit">
    <PageHeader title="Nạp tiền" :from-deposit="true" data-tooltip="" :show-home-from-page="true" />
    <div>
      <div class="content">
        <div
          v-for="(item, index) in menuDeposit"
          :key="index"
          :class="`menu-item ${item.isMaintain ? 'maintain' : ''}`"
          @click="handleTabMenuDeposit(item)"
        >
          <CommonBaseImg
            v-if="item.isHot && !item.isMaintain"
            class="icon-hot"
            src="/assets/images/components/mobile/pages/deposit/tag-hot.svg"
            alt="tag"
          />
          <CommonBaseImg
            v-if="item.isNew && !item.isMaintain"
            class="icon-new"
            src="/assets/images/components/mobile/pages/deposit/tag-new.svg"
            alt="tag"
          />
          <CommonBaseImg
            v-if="item.isMaintain"
            class="icon-maintain"
            src="/assets/images/components/mobile/pages/deposit/tag-menu-maintain.png"
            alt="tag"
          />
          <span class="icon" :class="item.icon" />
          <div class="title">{{ item?.nameMB }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import PageHeader from '~/components/mobile/common/page-header/index.vue'
import CommonBaseImg from '~/components/common/base-img'
import { useAppStore } from '~/store/app'
import { MENU_DEPOSIT, NOTE } from '~/constants/menu'
import { PAGE_URLS, ACCOUNT_URLS } from '~/config/page-url'
import { useToggleMenu } from '~/composables/useToggleMenu'
import { useDepositMenu } from '~/composables/account/deposit/useMenuDeposit'
import { IMenuDeposit } from '~/types/menu.type'

const { toggleMenuDeposit } = useToggleMenu()
const { $pinia } = useNuxtApp()
const { menuDeposit, handleTabMenuDeposit } = useDepositMenu()
const store = useAppStore($pinia)
const { openDepositFrom, openWithdrawFrom } = storeToRefs(store)
const loading = ref(true)
const route = useRoute()
const router = useRouter()
const { setOpenWithdrawFrom } = store

let previousPath = ''

const handleShowRightSlider = () => {
  const body: any = document.querySelector('body')
  if (openDepositFrom.value) {
    if (body.classList.contains('show-menu-deposit')) {
      body.classList.remove('show-menu-deposit')

      if (window.location.pathname.includes(ACCOUNT_URLS.DEPOSIT)) {
        if (previousPath !== '') {
          router.replace(previousPath)
        } else {
          router.replace(PAGE_URLS.HOME)
        }
      }
      if (
        previousPath === PAGE_URLS.HOME ||
        previousPath.includes('casino') ||
        previousPath.includes('games') ||
        previousPath.includes('games-lottery')
      ) {
        router.replace(previousPath)
      }
    } else {
      if (body.classList.contains('show-menu-common')) {
        body.classList.remove('show-menu-common')
        return
      }
      if (
        route.path === PAGE_URLS.HOME ||
        route.name === 'casino' ||
        route.name === 'games' ||
        route.name === 'games-lottery'
      ) {
        previousPath = route.fullPath
        toggleMenuDeposit()
      } else {
        previousPath = ''
      }
    }
  } else if (body.classList.contains('show-menu-account')) {
    body.classList.remove('show-menu-account')
  }

  if (openWithdrawFrom.value === 'account') {
    if (body.classList.contains('show-menu-withdraw')) {
      setOpenWithdrawFrom('')
      body.classList.remove('show-menu-withdraw')
    } else {
      body.classList.add('show-menu-withdraw')
    }
  }
}

onMounted(() => {
  window.addEventListener('popstate', function (event) {
    handleShowRightSlider()
  })

  let currentPath = route?.fullPath
  if (currentPath === ACCOUNT_URLS.DEPOSIT) {
    currentPath += '/bank'
    useRouter().replace({ path: currentPath })
  }
  setTimeout(() => {
    loading.value = false
  }, 500)
  const pageNotMaintain = menuDeposit.value.filter((item: IMenuDeposit) => !item.isMaintain)
  const currentPage = menuDeposit.value.find((item: IMenuDeposit) => item.queryValue === currentPath)
  if (currentPage?.isMaintain) {
    useRouter().replace({
      path: ACCOUNT_URLS.DEPOSIT,
      query: { type: pageNotMaintain[0]?.queryValue }
    })
  }
})

onUnmounted(() => {
  window.removeEventListener('popstate', handleShowRightSlider)
})
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/common/page-deposit/index.scss" />
